@import 'shared/shared';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/breakpoints';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/colors';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/colorsRGB';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/fontFamilies';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/fontSizes';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/fontWeights';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/gradients';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/letterSpacings';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/lineHeights';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/opacities';
@import './node_modules/@nbcnews/design-tokens/tokens/telemundoentretenimiento/spacing';
// Helper
@import '../utils/functions';

// GLOBAL VARIABLES
$theme: 'telemundoentretenimiento';

// Background
$default-background: $white;

// Colors
$default-color: $red-50;
$default-neutral: $neutral-10;

// Gradient
$default-gradient: to-list($blue-blue-gradient);
$gradient-value-1: #27344f;
$gradient-value-2: #4ca3ff;

// Images
$default-img-border-radius: 0%;

// Links
$default-hover-opacity: $opacity-70;
$default-active-opacity: $opacity-50;

// Headlines
$default-headline-color: $grey-70;
$default-headline-leading: $leading-100;
$default-headline-font-family: $founders-cond;

// Body text
$default-text-size: $text-18;
$default-text-color: $grey-70;
$default-text-leading: $leading-150;
$default-text-font-family: $publico-txt;

:root {
  /* Colors */
  --default-color: var(--red-50);
  --default-color-rgb: var(--red-50-rgb);
  --default-neutral: var(--neutral-10);
  --default-neutral-rgb: var(--neutral-10-rgb);
  --primary-color: var(--default-color);
  --primary-color-rgb: var(--default-color-rgb);

  /* Gradient */
  --default-gradient: var(--blue-blue-gradient-stop-1), var(--blue-blue-gradient-stop-2);
  --gradient-value-1: var(--blue-blue-gradient-stop-1);
  --gradient-value-1-rgb: #{color-to-rgb($gradient-value-1)};
  --gradient-value-2: var(--blue-blue-gradient-stop-2);
  --gradient-value-2-rgb: #{color-to-rgb($gradient-value-2)};

  /* Images */
  --default-img-border-radius: 0%;

  /* Links */
  --default-hover-opacity: var(--opacity-70);
  --default-active-opacity: var(--opacity-50);

  /* Headlines */
  --default-headline-color: var(--grey-70);
  --default-headline-color-rgb: var(--grey-70-rgb);
  --default-headline-leading: var(--leading-100);
  --default-headline-font-family: var(--founders-cond);
  --primary-font: var(--default-headline-font-family);

  /* Body text */
  --default-text-size: var(--text-18);
  --default-text-color: var(--grey-70);
  --default-text-color-rgb: var(--grey-70-rgb);
  --default-text-leading: var(--leading-150);
  --default-text-font-family: var(--publico-txt);
  --secondary-font: var(--default-text-font-family);
}
